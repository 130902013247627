













import { Component, Vue } from 'vue-property-decorator';
import { HistoryContent } from '@/types/neuhaus-website/cms/pages/history';
import axios, { APIResponse } from '@/plugins/axios';


/**
 * CMSPageHistory Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-timeline': () => import('@/components/cms/components/CMSComponentTimeline.vue')
    }
})
export default class CMSPageHistory extends Vue {
    // variable with page content
    private pageContent: HistoryContent = {
        id: -1,
        title: 'N/A',
        timeline: {
            id: -1,
            items: []
        }
    };

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns HistoryContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<HistoryContent> {
        try {
            const response = await axios.get<APIResponse<HistoryContent>>('/cms/pages/history')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }
}
